import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import dayjs from "dayjs"

import Layout from "../components/layout"
import PageBanner from "../components/pagebanner"
import Seo from "../components/seo"
import SelectCategories from "../components/blog/select-categories"
import SelectTags from "../components/blog/select-tags"

interface BlogArchiveProps {
  data: any;
  pageContext: any;
}

const BlogArchive: React.FC<BlogArchiveProps> = ({ data, pageContext: { author, category, tag, pageNumber, totalPages, nextPagePath, previousPagePath } }) => {
  return (
    <Layout>
      <PageBanner>Blog Posts</PageBanner>
      
      <div className="container">
        <div className="row">
          {data.allBlogpostsJson.nodes.map((post: any) => (
            <div className="col-md-4" key={post.slug}>
              <div className="fh5co-property">
                <figure>
                  <Link to={`/${post.slug}`}>
                    <GatsbyImage image={post.image.src.childImageSharp.gatsbyImageData} alt={post.image.name} objectFit="cover" />
                  </Link>
                  <span className="tag">{dayjs(post.postDate).format("MMM D YYYY")}</span>
                </figure>
                <div className="fh5co-property-innter">
                  <h3><Link to={`/${post.slug}`}>{post.title}</Link></h3>
                  <p>{`${post.content.replace(/\n\n/g, " ").substring(0, 100).trim()} `}<Link to={`/${post.slug}`}>&#8230; More &gt;</Link></p>
                </div>
                
                <p className="fh5co-property-specification">
                  <span><strong>By</strong> PlexTech Marketing</span>
                </p>
                  
              </div>
            </div>
          ))}
        </div>
        
        <div className="row page-navigation">
          {`${pageNumber} of ${totalPages}`}
        </div>
      </div>
    </Layout>
  )
}

export default BlogArchive


const seo = {
  metaDesc: "Check out the PlexTech.info Blog to learn more about how marketing experts complete projects.",
  opengraphTitle: "Blog",
  opengraphDescription: "Check out the PlexTech.info Blog to learn more about how marketing experts complete projects.",
  schema: {
    raw: `{
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "WebPage",
                "@id": "https://plextech.info/blog/",
                "url": "https://plextech.info/blog/",
                "name": "Blog - Marketing Experts"
            },
            {
                "@type": "WebSite",
                "@id": "https://plextech.info/#website",
                "url": "https://plextech.info/",
                "name": "Marketing Experts",
                "description": "Individual &amp; Business Marketing Solutions",
                "publisher": {
                    "@id": "https://plextech.info/#organization"
                },
                "inLanguage": "en-US"
            }
        ]
    }`
  }
}

export const pageQuery = graphql`
  query BlogPostArchive($offset: Int!, $postsPerPage: Int!) {
    allBlogpostsJson(
      sort: {postDate: DESC},
      limit: $postsPerPage,
      skip: $offset
    ) {
      nodes {
        title,
        slug,
        content,
        postDate,
        image {
          name,
          src {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`

export const Head = () => <Seo title="Blog - PlexTech Marketing" type="article" url="/blog/" seo={seo} />
