import React, { useEffect, useState } from "react"

interface PageBannerProps {
  children?: React.ReactNode;
}

const PageBanner: React.FC<PageBannerProps> = ({ children }) => {
  return (
    <div className="fh5co-page-title">
      <div className="overlay"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>{children}</h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageBanner
